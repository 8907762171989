<template>
  <div class="p-0">
    <div class="bg-white p-8 rounded-lg shadow-xl py-8 mt-12">
      <h3 class="text-lg font-semibold">Documentação - Inteligência Artificial</h3>
      <div class="pb-12 mt-4">

        <!-- Layout Responsivo -->
        <div class="flex min-h-screen flex-row py-8">
          <!-- Sidebar -->
          <Menu />
          <!-- Main Content -->
          <main class="flex-1 px-6 bg-gray-100">
            
            <h1 class="text-2xl font-bold mb-4">Importância da Estruturação do Prompt de Atendimento</h1>
  <p class="mb-4">
    Um ponto fundamental para o sucesso do chatbot é a criação de um prompt de atendimento bem estruturado. O prompt serve como uma "instrução" que orienta a IA sobre como se comportar, o que priorizar e até que ponto utilizar as informações da base de conhecimento.
  </p>

  <h2 class="text-xl font-semibold mb-3">O que é um Prompt de Atendimento?</h2>
  <p class="mb-4">
    O prompt é um conjunto de instruções que detalham o comportamento esperado do chatbot, como ele deve responder, quais informações ele deve ou não fornecer, e as regras de interação com os usuários.
  </p>
  <p class="mb-4 bg-gray-50 p-4 rounded-md">
    Exemplo genérico de um prompt básico:<br />
    "Responder perguntas sobre produtos com base no documento X."<br />
    "Nunca fornecer dados sobre preços, apenas encaminhar ao setor comercial."
  </p>

  <h2 class="text-xl font-semibold mb-3">Como Estruturar o Prompt</h2>
  <p class="mb-4">
    A organização do prompt é fundamental para que a IA compreenda seu papel e saiba como interagir com os usuários. Aqui estão os principais tópicos a serem considerados ao organizar a estrutura do prompt:
  </p>

  <ul class="list-disc list-inside mb-4">
    <li class="mb-2"><strong>Identificação:</strong> Especifique o propósito do chatbot, incluindo seu nome, setor de atuação e a função que ele deve desempenhar.</li>
    <li class="mb-2"><strong>Objetivo:</strong> O prompt deve deixar claro qual é o objetivo principal do chatbot: qualificar leads, fornecer informações sobre produtos, agendar visitas, etc.</li>
    <li class="mb-2"><strong>Tonalidade e estilo de comunicação:</strong> Defina o tom e o estilo de comunicação. Por exemplo, o chatbot deve ser formal, amigável, técnico ou consultivo? Ele deve usar listas, texto corrido, emojis?</li>
    <li class="mb-2"><strong>Estruturando a base de conhecimento:</strong> Garanta que a base de conhecimento esteja organizada e que o chatbot saiba onde localizar e como usar essas informações.</li>
    <li class="mb-2"><strong>Perguntas e respostas comuns:</strong> Liste perguntas e respostas comuns que o chatbot deverá lidar, com exemplos de bons comportamentos e comportamentos a serem evitados.</li>
    <li class="mb-2"><strong>Ações específicas:</strong> Inclua instruções sobre ações como direcionar consultas complexas para humanos ou agendar compromissos.</li>
    <li class="mb-2"><strong>Escopo e limitações:</strong> Defina claramente o que o chatbot pode ou não fazer, e como lidar com solicitações fora do escopo.</li>
    <li class="mb-2"><strong>Exemplos de diálogos:</strong> Ofereça exemplos de diálogos para guiar o chatbot em diferentes cenários.</li>
    <li class="mb-2"><strong>Instruções padrões:</strong> Crie instruções para o que fazer em caso de dúvidas ou quando transferir para um humano.</li>
    <li class="mb-2"><strong>Recompensa:</strong> Determine como o chatbot deve agir após uma interação bem-sucedida, como encerrar educadamente ou fornecer uma mensagem de agradecimento.</li>
  </ul>

  <h2 class="text-xl font-semibold mb-3">Aprimorando o prompt:</h2>
  <p class="mb-4">
    Para aprimorar o prompt no Duotalk e garantir respostas mais eficazes, recomendamos realizar testes no ambiente de simulação para identificar ajustes e melhorar a precisão. A formatação do texto em Markdown é essencial para a IA compreender corretamente o prompt. Siga estas práticas:
  </p>

  <ul class="list-disc list-inside mb-4">
    <li class="mb-2">Use <code>#</code> para títulos principais e <code>##</code> para subtítulos.</li>
    <li class="mb-2">Utilize listas com <code>-</code> ou <code>*</code> para estruturar tópicos.</li>
    <li class="mb-2">Destaque palavras-chave com <code>*negrito*</code> (ex.: <strong>TRANSFERIR</strong> ou <strong>ENCERRAR</strong>).</li>
    <li class="mb-2">Organize etapas numeradas (1., 2., 3.) para instruções complexas.</li>
    <li class="mb-2">Tabelas com pipes “|” podem ajudar a estruturar dados específicos:</li>
  </ul>

  <div class="bg-gray-50 p-4 rounded-md">
    <pre class="whitespace-pre-wrap font-mono">
| Modelo    | Preço            |
|-----------|------------------|
| HB20      | R$ 70.000,00     |
| CRETA     | R$ 100.000,00    |
    </pre>
  </div>

          </main>
        </div>

      </div>
    </div>
  </div>
</template>
    
    
      
      <script>
      import Menu from './MenuFaqIA.vue'; // Importando o menu
      export default {
        name: 'ChatbotIA-BaseConhecimento',
        components: {
            Menu
        },
        data() {
        return {
          // Lista de perguntas e respostas, o primeiro item já vem com `active: true`
          faqs: [
            { question: "O que é Duotalk?", answer: "Duotalk é uma plataforma que oferece soluções de atendimento automatizado.", active: true },
            { question: "Como configurar a inteligência artificial?", answer: "Você pode configurar a inteligência artificial seguindo as instruções no nosso guia.", active: false },
            { question: "Quais são os planos disponíveis?", answer: "Os planos variam de acordo com o número de usuários e as funcionalidades necessárias.", active: false },
          ]
        };
      },
      methods: {
        // Alterna a visibilidade da resposta
        toggleFaq(index) {
          this.faqs[index].active = !this.faqs[index].active;
        }
      }
    };
    
    </script>
    
    
    <style scoped>

    
    .faq-item h3 {
      cursor: pointer;
      padding: 10px;
      background-color: #f2f2f2;
      border-radius: 5px;
      margin: 5px 0;
      transition: background-color 0.3s ease;
    }
    
    .faq-item h3:hover {
      background-color: #e0e0e0;
    }
    
    .faq-item h3.active {
      background-color: #d0d0d0;
    }
    
    .faq-item p {
      margin: 10px 0;
      padding: 10px;
      background-color: #fafafa;
      border-left: 5px solid #cccccc;
    }
    </style>
   